import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const ThankYouPage = () => {
  return (
    <Layout>
      <SEO
        title={"Thank you"}
        description={"The meeting place for people, products, and great design"}
      />
      <section className="slide whiteSlide kenBurns">
        <div className="content">
          <div className="container">
            <div className="info-box-md center">
              <img
                alt="Success"
                src="/assets/img/check-mark.png"
                className="ae-1"
                width="60px"
              />
              <h2 className="ae-1 normal margin-bottom-2">Awesome!</h2>
              <p className="ae-2 large margin-bottom-5">
                <span className="opacity-8">
                  Thanks for your interest in joining The Unflux. We will contact you with how to proceed from here.
                </span>
              </p>
              <a className="ae-2 button rounded" href="/learn">
                Continue
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ThankYouPage;
